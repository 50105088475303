<template>
  <div>
    <div class="vote_title y_center">{{$t('Vote.title')}}</div>
    <div style="padding:0 10px;">
      <div class="LP_box y_center" v-for="(item,index) in list" :key="index">
        <div class="vote_time" v-if="countTime(time,item.start_timestamp).time>0"><span>{{countTime(time,item.start_timestamp).d}}</span>{{$t('Vote.d')}}<span>{{countTime(time,item.start_timestamp).h}}</span>{{$t('Vote.h')}}<span>{{countTime(time,item.start_timestamp).m}}</span>{{$t('Vote.m')}}<span>{{countTime(time,item.start_timestamp).s}}</span>{{$t('Vote.s')}}</div>
        <div class="vote_time" v-if="countTime(time,item.start_timestamp).time<=0"><span>{{countTime(time,item.end_timestamp).d}}</span>{{$t('Vote.d')}}<span>{{countTime(time,item.end_timestamp).h}}</span>{{$t('Vote.h')}}<span>{{countTime(time,item.end_timestamp).m}}</span>{{$t('Vote.m')}}<span>{{countTime(time,item.end_timestamp).s}}</span>{{$t('Vote.s')}}</div>
        <div class="vote_text">{{item.title}}</div>
        <div class="vote_btn flex_center hand" :class="countTime(time,item.start_timestamp).time>0?'vote_btn_close':countTime(time,item.end_timestamp).time>0?'vote_btn_open':'vote_btn_close'" @click="toPage('/vote_details?id='+item.id)">{{countTime(time,item.start_timestamp).time>0?$t('Vote.text13'):countTime(time,item.end_timestamp).time>0?$t('Vote.join'):$t('Vote.end')}}</div>
      </div>
    </div>
  </div>
</template>
 
<script>
import walletHelper from "@/utils/walletHelper.js"
export default {
  data () {
    return {
      list:[],
      current_page:0,
      last_page:0,
      is_next:false,
      time:0,
      interval:''
    }
  },
  computed: {
    userInfo() {
			return this.$store.state.user.userInfo
		},
	},
  created() {
    window.addEventListener('scroll', this.Scrollbottom);  //页面加载时监听滚动事件
  },
  destroyed() {
    window.removeEventListener('scroll', this.Scrollbottom) //页面离开后销毁监听事件
  },
  mounted () {
    this.init()
  },
  methods: {
    init() {
      if(this.is_next)return
      this.is_next = true
      this.get('api/Vote/index', {page:this.current_page}, 'POST').then(res => {
        this.is_next = false
        this.last_page = res.list.last_page;//分页总数
        this.current_page = res.list.current_page;//当前页
        this.time = res.time
        if (this.current_page==1) {
          this.list = res.list.data
        }else {
          if(res.list.data.length>0){
            this.list = this.list.concat(res.list.data)
          }
        }
        if(this.interval==''){
          this.interval = setInterval(()=>{
            this.time++
          },1000)
        }
      })
    },
    Scrollbottom() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.next_pages()
      }
    },
    next_pages(){
      if(this.tableData!=undefined&&this.tableData.length>0){
				this.current_page++
				if (this.last_page<this.current_page) {
					return
				}
				this.init()
			}
    },
    countTime(starttime,endtime){
      var start_time = new Date(starttime).getTime()*1000
      var end_time = new Date(endtime).getTime()*1000
      var time = end_time-start_time
      var d = 0
      var h = 0
      var m = 0
      var s = 0
      if(time<=0){
        time = 0
        d = '00'
        h = '00'
        m = '00'
        s = '00'
      }
      d = Math.trunc(time / (1000 * 60 * 60 * 24));
      h = parseInt(time % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
      m = parseInt(time % (1000 * 60 * 60) / (1000 * 60));
      s = time % (1000 * 60) / 1000;
      if (d < 10) {
        d = '0' + d
      }
      if (h < 10) {
        h = '0' + h
      }
      if (m < 10) {
        m = '0' + m
      }
      if (s < 10) {
        s = '0' + s
      }
      var time_arr={
        d:d,
        h:h,
        m:m,
        s:s,
        time:time
      }
      return time_arr
    },
    copy(){
      this.$copyText(this.addressToken).then(e=>{
        this.$toast(this.$t('tips.title2'))
      }, e=> {
        this.$toast(this.$t('tips.title3'))
      })
    },
    toPage(url){
      this.$router.push(url)
    },
  }
}
</script>

<style lang="scss" scoped>
  .vote_title{
    font-size: 1.2rem;
    color: #ffffff;
    padding: 20px 0;
  }
  .LP_box{
    width: 100%;
    background: #041453;
    border-radius: 8px;
    padding: 10px 15px;
    margin-bottom: 10px;
    color: #ffffff;
  }
  .vote_time{
    font-size: 0.9rem;
    margin: 10px 0;
    span{
      margin: 0 5px;
      padding: 2px 5px;
      background: #00EAFF;
      color: #000;
      font-weight: bold;
    }
  }
  .vote_text{
    width: 100%;
    font-size: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .vote_btn{
    width: 100%;
    height: 35px;
    font-size: 1rem;
    margin: 10px 0;
  }
  .vote_btn_open{
    background: linear-gradient(to right, transparent 0%, rgba(0, 234, 255, 0.6) 15% 85%, transparent 100%);
  }
  .vote_btn_close{
    background: linear-gradient(to right, transparent 0%, rgba(0, 234, 255, 0.3) 15% 85%, transparent 100%);
  }
</style>